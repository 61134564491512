import {
    EncoderCalibStatus,
    EncoderDirection,
    HallsensorCalibStatus,
    HallsensorDirection,
    IEncoderCalResultResponse,
    IHallSensorCalResultResponse,
    IHandshakeResponse,
    IStartParamDetectRequest,
    IStartParamDetectResponse,
    IWritePowerConfigResponse,
    PacketId,
    deserialize,
    inputReportID,
    serializeStartEncoderCalRequest,
    serializeStartHallSensorCalRequest,
    serializeStartParamDetectRequest,
    serializeWritePowerConfigRequest,
} from "../utils/common-types";
import { IEncoderState, IHallSensorState } from "../hooks/useDevices";
import {
    HIDRequestMultiTimeout,
    hidRequest,
    hidRequestMulti,
    hidSend,
} from "./hid";

enum FirmwareUpdateCommand {
    ERASE_BANK = 1,
    PROGRAM_MEMORY,
    END_OF_UPDATE,
    READ_MEMORY,
    GET_FLASH_INFO,
    ERASE_FLASH_CONFIG,
    WRITE_FLASH_CONFIG,
}

export enum FlashTarget {
    FlashExecutable,
    FlashConfig,
    FlashExeAndConfig,
}

interface IFirmwareUpdateRequest {
    command: FirmwareUpdateCommand;
    byteOffset?: number;
    chunk?: Uint32Array;
}

const DFU_VENDOR_ID = 0x0483;
const DFU_PRODUCT_ID = 0xdf11;
const DFU_PRODUCT_NAME = "Dust"

const PROGRAM_CHUNK_WORD_SIZE = 12; // 12 x uint32

const PARAM_DETECT_INITIAL_TIMEOUT_MS = 20000; // 20 seconds from user initiation to first packet
const PARAM_DETECT_V1_TIMEOUT_MS = 45000; // 45 seconds from first packet to second, but can be lengthened
const ENCODER_CALIBRATION_TIMEOUT_MS = 120000; // 2 minutes
const HALLSENSOR_CALIBRATION_TIMEOUT_MS = 120000; // 2 minutes

const CSTORE_MAGIC_STRING = "CNFGSTRE";
const CSTORE_MAGIC_BYTES = Uint8Array.from(CSTORE_MAGIC_STRING.split("").map(x => x.charCodeAt(0)));
const CSTORE_SIZE = 2048;

const u32String = ( u32: number ) => {
    return u32.toString( 16 ).padStart( 8, "0" );
};

export const deviceId = ( handshakeResponse: IHandshakeResponse ) => {
    return `${ u32String( handshakeResponse.deviceId1 ) }${ u32String( handshakeResponse.deviceId2 ) }${ u32String( handshakeResponse.deviceId3 ) }`.toUpperCase();
};

/*
 * Parameter detection has four steps:
 * 1. Estimate R (resistance).
 * 2. Estimate Ld (D component of inductance).
 * 3. Estimate Lq (Q component of inductance). After this step, a
 *    START_PARAM_DETECT response with 0 flux linkage is sent.
 * 4. Estimate flux linkage. The motor spins. After this step, another
 *    START_PARAM_DETECT response is sent, which should have nonzero flux
 *    linkage.
 */
export const runParameterDetection = async (
    device: HIDDevice,
    powerConfig: IWritePowerConfigResponse,
    manualParamDetectionParams: IStartParamDetectRequest,
    polePairCount?: number,
): Promise<IStartParamDetectResponse> => {

    // if polePairCount is undefined, we are running parameter detection V1
    // ToDo: clean up dead code and remove polePairCount as parameter to this function.
    const paramDetectTimeoutMs = PARAM_DETECT_V1_TIMEOUT_MS;
    if (polePairCount) {
        if (polePairCount === 0 || !isFinite(polePairCount)) {
            throw new Error("Pole pair count is required for parameter detection. Please provide this value on the 'Throttle' tab.");
        }
    }

    // Send limits first, for safety.
    await hidRequest( device, PacketId.WRITE_POWER_CONFIG, serializeWritePowerConfigRequest( powerConfig ) );

    // Note: _not_ an async call, below!
    // Both paramDetectMaxCurrent and paramDetectMaxRpm are 0 in firmware if not specified.
    const responsePromises = hidRequestMulti( device, PacketId.START_PARAM_DETECT, serializeStartParamDetectRequest({
        paramDetectMaxCurrent: manualParamDetectionParams.paramDetectMaxCurrent,
        paramDetectMaxRpm: manualParamDetectionParams.paramDetectMaxRpm,
    }), [ PARAM_DETECT_INITIAL_TIMEOUT_MS, paramDetectTimeoutMs ] );

    // Resistance and inductance estimation (steps 1-3).
    let response;
    try {
        response = await responsePromises[ 0 ];
    } catch ( e ) {
        if ( !( e instanceof HIDRequestMultiTimeout ) ) throw e; // Re-throw unexpected errors
        throw new Error( `Parameter detection timed out after ${ PARAM_DETECT_INITIAL_TIMEOUT_MS / 1000 } seconds. ` +
                         "Resistance and inductance could not be estimated." );
    }

    if ( response.ld === 0 || response.lq === 0 || response.resistance === 0 ) {
        throw new Error( paramDetectFailMessage( response ) );
    }

    // Flux linkage estimation (step 4).
    try {
        response = await responsePromises[ 1 ];
    } catch ( e ) {
        if ( !( e instanceof HIDRequestMultiTimeout ) ) throw e; // Re-throw unexpected errors
        throw new Error( "Flux Linkage estimation timed out." );
    }

    if ( response.fluxLinkage === 0 || response.ld === 0 || response.lq === 0 || response.resistance === 0 ) {
        throw new Error( paramDetectFailMessage( response ) );
    }

    return response;
}

const paramDetectFailMessage = ( response: IStartParamDetectResponse ): string => {
    const disp = ( n: number ) => n.toExponential( 6 ); // Display number

    if ( response.resistance === 0 ) {
        return "Resistance could not be estimated.";
    }
    if ( response.lq === 0 ) {
        if ( response.ld === 0 ) {
            return `Inductance was not measured. Is the estimated resistance (${ disp( response.resistance ) }) correct?`;
        } else {
            return `Inductance estimation failed: R = ${ disp( response.resistance ) }, Ld = ${ disp( response.ld ) }, Lq = ${ disp( response.lq ) }`;
        }
    }
    return "Flux linkage estimation failed."; // Unknown failure mode
};

// Wraps _runEncoderCalibration with state updating.
export const runEncoderCalibration = async (
    device: HIDDevice,
    encoderState: IEncoderState,
    setEncoderState: (state: IEncoderState) => void ): Promise<EncoderCalibStatus> => {
    const { status, offset } = await _runEncoderCalibration( device );
    setEncoderState({
        ...encoderState,
        status,
        offset,
    });
    return status;
}

export const runHallSensorCalibration = async ( device: HIDDevice, hallSensorState: IHallSensorState, setHallSensorState: (state: IHallSensorState) => void ): Promise<HallsensorCalibStatus> => {
    const { forwardOffset, reverseOffset, status, direction } = await _runHallSensorCalibration( device );
    setHallSensorState({
        ...hallSensorState,
        forwardOffset,
        reverseOffset,
        status,
        direction,
    });
    return status;
}

const _runEncoderCalibration = ( device: HIDDevice ): Promise<IEncoderCalResultResponse> => {
    return new Promise( ( resolve, reject ) => {
        let timeout: NodeJS.Timeout | undefined;
        const startTimeout = () => {
            clearTimeout( timeout );
            timeout = setTimeout( () => {
                device.removeEventListener( "inputreport", listener );
                resolve( { status: EncoderCalibStatus.FAILURE_TIMEOUT, offset: NaN, direction: EncoderDirection.UNKNOWN} );
            }, ENCODER_CALIBRATION_TIMEOUT_MS );
        };

        const listener = ( event: HIDInputReportEvent ) => {
            const id = inputReportID( event.data );
            if ( id !== PacketId.ENCODER_CAL_RESULT ) {
                return;
            }

            const response = deserialize( event.data ) as IEncoderCalResultResponse;

            clearTimeout( timeout );
            device.removeEventListener( "inputreport", listener );
            resolve( response );
        };

        startTimeout();
        device.addEventListener( "inputreport", listener );
        hidSend( device, serializeStartEncoderCalRequest({}) );
    } );
}

const _runHallSensorCalibration = ( device: HIDDevice ): Promise<IHallSensorCalResultResponse> => {
    return new Promise( ( resolve, reject ) => {
        let timeout: NodeJS.Timeout | undefined;
        const startTimeout = () => {
            clearTimeout( timeout );
            timeout = setTimeout( () => {
                device.removeEventListener( "inputreport", listener );
                resolve( { status: HallsensorCalibStatus.FAILURE_TIMEOUT, forwardOffset: NaN, reverseOffset: NaN, direction: HallsensorDirection.UNKNOWN} );
            }, HALLSENSOR_CALIBRATION_TIMEOUT_MS );
        };

        const listener = ( event: HIDInputReportEvent ) => {
            const id = inputReportID( event.data );
            if ( id !== PacketId.HALL_SENSOR_CAL_RESULT ) {
                return;
            }

            const response = deserialize( event.data ) as IHallSensorCalResultResponse;

            clearTimeout( timeout );
            device.removeEventListener( "inputreport", listener );
            resolve( response );
        };

        startTimeout();
        device.addEventListener( "inputreport", listener );
        hidSend( device, serializeStartHallSensorCalRequest({}) );
    } );
}

const fileToBytes = ( file: File ): Promise<Uint8Array> => {
    return new Promise( ( resolve, reject ) => {
        const reader = new FileReader();
        reader.onloadend = ( e ) => {
            if ( e.target === null || !isDefined( e.target.result ) || e.target.readyState !== FileReader.DONE || typeof e.target.result !== "object" ) {
                reject();
                return;
            }

            const buffer = e.target.result;
            resolve( new Uint8Array( buffer ) );
        };
        reader.readAsArrayBuffer( file );
    } );
};

const serializeFirmwareUpdateRequest = ( request: IFirmwareUpdateRequest ): BufferSource => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.FIRMWARE_UPDATE );
    dv.setUint8( 1, request.command );
    if ( !isDefined( request.chunk ) || !isDefined( request.byteOffset ) ) {
        return dv;
    }
    dv.setUint32( 4, request.chunk.length * 4, true );
    dv.setUint32( 8, request.byteOffset, true );
    for ( const [ i, u32 ] of request.chunk.entries() ) {
        dv.setUint32( 12 + ( i * 4 ), u32, true );
    }
    return dv;
};

export const enterDFUMode = async ( hid: HIDDevice ): Promise<USBDevice> => {
    await hidSend( hid, serializeFirmwareUpdateRequest( { command: FirmwareUpdateCommand.END_OF_UPDATE } ) );
    const device = await navigator.usb.requestDevice( {
        filters: [ { vendorId: DFU_VENDOR_ID, productId: DFU_PRODUCT_ID } ],
    } );

    return device;
};

export const supportsDFU = ( device: HIDDevice ): boolean => {
    return device.productName.match(DFU_PRODUCT_NAME) !== null;
};

export const flashFirmware = async ( device: HIDDevice, file: File, flashTarget: FlashTarget, callback?: ( progress: number ) => void ) => {
    let updater;
    if ( supportsDFU( device ) ) {
        const dfuDevice = await enterDFUMode( device );
        updater = new FirmwareUpdateDFU( dfuDevice, callback );
    } else {
        updater = new FirmwareUpdateHID( device, callback );
    }

    return await updater.run( file, flashTarget );
};

export const backupFirmware = async ( device: HIDDevice, callback?: ( progress: number ) => void ) => {
    if ( supportsDFU( device ) ) {
        throw new Error("This device does not support firmware backup");
    }

    let backup = new FirmwareBackupHID( device, callback );

    return await backup.run();
};

export const writeFlashConfig = async ( device: HIDDevice, file: File, callback?: ( progress: number ) => void ) => {
    if ( supportsDFU( device ) ) {
        throw new Error("This device does not support this action");
    }

    let updater = new FirmwareUpdateHID( device, callback );
    return await updater.run( file, FlashTarget.FlashConfig );
};

class PermanentFailure extends Error {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, PermanentFailure.prototype);
    }
}

class FirmwareUpdateHID {
    private device: HIDDevice;
    private callback?: ( progress: number ) => void;

    constructor( device: HIDDevice, callback?: ( progress: number ) => void ) {
        this.device = device;
        this.callback = callback;
    }

    run = async ( file: File, target: FlashTarget ): Promise<boolean> => {
        let attempts = 3;
        while ( attempts-- > 0 ) {
            try {
                switch (target)
                {
                    case FlashTarget.FlashExecutable:
                        await this.eraseBank();
                        await this.flashBinary( file, target );
                        await this.finalizeFirmwareUpdate();
                        break;
                    case FlashTarget.FlashConfig:
                        await this.eraseConfigRegion();
                        await this.flashBinary( file, target);
                        await this.finalizeFirmwareUpdate();
                        break;
                    case FlashTarget.FlashExeAndConfig:
                        await this.eraseBank();
                        await this.eraseConfigRegion();
                        await this.flashBinary( file, target);
                        await this.finalizeFirmwareUpdate();
                        break;
                    default:
                        console.error( "Unknown flash target", target );
                        return false;
                }

                return true;
            } catch ( e ) {
                console.warn( e );
                if ( attempts > 0 ) {
                    if ( e instanceof PermanentFailure ) {
                        break;
                    } else {
                        console.warn( `Retrying ({attempts} more attempts)` );
                    }
                }
            }
        }

        return false;
    };

    private eraseBank = async (): Promise<DataView> => {
        return new Promise( ( resolve, reject ) => {
            let timeout = setTimeout( () => {
                this.device.removeEventListener( "inputreport", listener );
                reject( "Firmware update timeout while attempting to erase bank" );
            }, 5000 );

            const listener = ( event: HIDInputReportEvent ) => {
                const id = inputReportID( event.data );
                if ( id !== PacketId.FIRMWARE_UPDATE ) {
                    return;
                }

                const command: FirmwareUpdateCommand = event.data.getUint8( 1 );
                if ( command !== FirmwareUpdateCommand.ERASE_BANK ) {
                    return;
                }

                clearTimeout( timeout );
                this.device.removeEventListener( "inputreport", listener );
                resolve( event.data );
            };

            this.device.addEventListener( "inputreport", listener );
            hidSend( this.device, this.serializeFirmwareUpdateRequest({ command: FirmwareUpdateCommand.ERASE_BANK }) );
        } );
    };

    private eraseConfigRegion = async (): Promise<DataView> => {
        return new Promise( ( resolve, reject ) => {
            let timeout = setTimeout( () => {
                this.device.removeEventListener( "inputreport", listener );
                reject( "Flash config timed out while attempting to erase the config region" );
            }, 5000 );

            const listener = ( event: HIDInputReportEvent ) => {
                const id = inputReportID( event.data );
                if ( id !== PacketId.FIRMWARE_UPDATE ) {
                    return;
                }

                const command: FirmwareUpdateCommand = event.data.getUint8( 1 );
                if ( command !== FirmwareUpdateCommand.ERASE_FLASH_CONFIG) {
                    return;
                }

                clearTimeout( timeout );
                this.device.removeEventListener( "inputreport", listener );
                resolve( event.data );
            };

            this.device.addEventListener( "inputreport", listener );
            hidSend( this.device, this.serializeFirmwareUpdateRequest({ command: FirmwareUpdateCommand.ERASE_FLASH_CONFIG }) );
        } );
    };
    private serializeFirmwareUpdateRequest = ( request: IFirmwareUpdateRequest ): BufferSource => {
        const dv = new DataView( new ArrayBuffer( 64 ) );
        dv.setUint8( 0, PacketId.FIRMWARE_UPDATE );
        dv.setUint8( 1, request.command );
        if ( !isDefined( request.chunk ) || !isDefined( request.byteOffset ) ) {
            return dv;
        }
        dv.setUint32( 4, request.chunk.length * 4, true );
        dv.setUint32( 8, request.byteOffset, true );
        for ( const [ i, u32 ] of request.chunk.entries() ) {
            dv.setUint32( 12 + ( i * 4 ), u32, true );
        }
        return dv;
    };

    private bytesToWords = ( bytes: Uint8Array ): Uint32Array => {
        const buf = new ArrayBuffer( Math.ceil( bytes.byteLength / 4 ) * 4 );
        const u8 = new Uint8Array( buf );
        u8.set( bytes, 0 );
        return new Uint32Array( u8.buffer );
    };

    private sendChunk = async ( expectedCommand: FirmwareUpdateCommand, chunk: Uint32Array, byteOffset: number ): Promise<DataView> => {
        return new Promise( ( resolve, reject ) => {
            let timeout = setTimeout( () => {
                this.device.removeEventListener( "inputreport", listener );
                reject( `Firmware update timeout at byte offset ${ byteOffset }` );
            }, 5000 );

            const listener = ( event: HIDInputReportEvent ) => {
                const id = inputReportID( event.data );
                if ( id !== PacketId.FIRMWARE_UPDATE ) {
                    return;
                }

                const command: FirmwareUpdateCommand = event.data.getUint8( 1 );
                if ( command !== expectedCommand ) {
                    return;
                }

                clearTimeout( timeout );
                this.device.removeEventListener( "inputreport", listener );
                resolve( event.data );
            };

            this.device.addEventListener( "inputreport", listener );
            hidSend( this.device, this.serializeFirmwareUpdateRequest({ command: expectedCommand, chunk, byteOffset }) );
        } );
    };

    private compareChunks = ( chunk1: Uint32Array, chunk2: Uint32Array ): boolean => {
        if ( chunk1.byteLength !== chunk2.byteLength ) {
            return false;
        }

        for ( const [ i, word ] of chunk1.entries() ) {
            if ( word !== chunk2[ i ] ) {
                return false;
            }
        }

        return true;
    };

    private flashBinary = async ( file: File, target: FlashTarget ): Promise<void> => {
        const bytes = await fileToBytes( file );
        let cstoreWordOffset: number | undefined;
        if ( target === FlashTarget.FlashExeAndConfig ) {
            // First part of the file is program memory; second, and smaller, part is cstore bin
            cstoreWordOffset = this.getCStoreRegionOffset( bytes ) / 4;
        }
        const words = this.bytesToWords( bytes );

        let offset = 0;
        while ( offset < words.length ) {
            let currentChunkSize = Math.min( PROGRAM_CHUNK_WORD_SIZE, words.length - offset );
            if ( cstoreWordOffset !== undefined && offset < cstoreWordOffset ) {
                currentChunkSize = Math.min( PROGRAM_CHUNK_WORD_SIZE, cstoreWordOffset - offset );
            }
            const currentChunk = words.slice( offset, offset + currentChunkSize );

            let response;
            switch(target)
            {
                case FlashTarget.FlashExecutable:
                    response = await this.sendChunk(FirmwareUpdateCommand.PROGRAM_MEMORY, currentChunk, offset * 4 );
                    break;
                case FlashTarget.FlashConfig:
                    response = await this.sendChunk(FirmwareUpdateCommand.WRITE_FLASH_CONFIG, currentChunk, offset * 4 );
                    break;
                case FlashTarget.FlashExeAndConfig:
                    if ( offset >= cstoreWordOffset! ) {
                        response = await this.sendChunk(FirmwareUpdateCommand.WRITE_FLASH_CONFIG, currentChunk, ( offset - cstoreWordOffset! ) * 4 );
                    } else {
                        response = await this.sendChunk(FirmwareUpdateCommand.PROGRAM_MEMORY, currentChunk, offset * 4 );
                    }
                    break;
                default:
                    return;
            }

            if ( !this.compareChunks( currentChunk, new Uint32Array( response.buffer.slice( 12, 12 + currentChunkSize * 4 ) ) ) ) {
                throw new Error( `Corrupted firmware update at byte offset: ${ offset * 4 }` );
            }

            offset += currentChunkSize;
            this.callback?.( offset / words.length );
        }
    };

    private getCStoreRegionOffset = ( fileBytes: Uint8Array ): number => {
        const isEqual = ( a1: Uint8Array, a2: Uint8Array ): boolean => {
            if ( a1.length !== a2.length ) {
                return false;
            }
            for ( let i = 0; i < a1.length; i++ ) {
                if ( a1[i] !== a2[i] ) {
                    return false;
                }
            }
            return true;
        };
        let offset = fileBytes.length - CSTORE_SIZE;
        if ( offset % 8 !== 0 || offset <= 0 ) {
            throw new PermanentFailure( "cstore magic bytes not found" );
        }

        const sub = fileBytes.subarray( offset, offset + 8 );
        if ( isEqual( sub, CSTORE_MAGIC_BYTES ) ) {
            return offset;
        } else {
            throw new PermanentFailure( "cstore magic bytes not found" );
        }
    };

    private finalizeFirmwareUpdate = async () => {
        await hidSend( this.device, this.serializeFirmwareUpdateRequest( { command: FirmwareUpdateCommand.END_OF_UPDATE } ) );
    };
}

class FirmwareBackupHID {
    private device: HIDDevice;
    private callback?: ( progress: number ) => void;

    constructor( device: HIDDevice, callback?: ( progress: number ) => void ) {
        this.device = device;
        this.callback = callback;
    }

    run = async (): Promise<boolean> => {
        let attempts = 3;
        while ( attempts-- > 0 ) {
            try {
                const bank_size_bytes = await this.GetFlashInfo();
                await this.readFlashMemory( bank_size_bytes );
                return true;
            } catch ( e ) {
                console.warn( e );
                if ( attempts > 0 ) {
                    if ( e instanceof PermanentFailure ) {
                        break;
                    } else {
                        console.warn( `Retrying ({attempts} more attempts)` );
                    }
                }
            }
        }

        return false;
    };

    private serializeFirmwareMgmtRequest = ( request: IFirmwareUpdateRequest ): BufferSource => {
        const dv = new DataView( new ArrayBuffer( 64 ) );
        dv.setUint8( 0, PacketId.FIRMWARE_UPDATE ); // More of a "Firmware Management" in this path
        dv.setUint8( 1, request.command );
        if ( !isDefined( request.chunk ) || !isDefined( request.byteOffset ) ) {
            return dv;
        }
        dv.setUint32( 4, request.chunk.length * 4, true );
        dv.setUint32( 8, request.byteOffset, true );
        // Zero out the command buffer that would contain data packets
        for ( let i = 0; i < request.chunk.length; i++ ) {
            dv.setUint32( 12 + ( i * 4 ), 0 , true );
        }
        return dv;
    };

    private requestFlashInfo = async ( ): Promise<DataView> => {
        return new Promise( ( resolve, reject ) => {
            let timeout = setTimeout( () => {
                this.device.removeEventListener( "inputreport", listener );
                reject( `Device flash info request timed out` );
            }, 5000 );

            const listener = ( event: HIDInputReportEvent ) => {
                const id = inputReportID( event.data );
                if ( id !== PacketId.FIRMWARE_UPDATE ) {
                    return;
                }

                const command: FirmwareUpdateCommand = event.data.getUint8( 1 );
                if ( command !== FirmwareUpdateCommand.GET_FLASH_INFO) {
                    return;
                }

                clearTimeout( timeout );
                this.device.removeEventListener( "inputreport", listener );
                resolve( event.data );
            };

            this.device.addEventListener( "inputreport", listener );
            hidSend( this.device, this.serializeFirmwareMgmtRequest({ command: FirmwareUpdateCommand.GET_FLASH_INFO }) );
        } );
    };


    private requestChunk = async ( chunk: Uint32Array, byteOffset: number, currentChunkSize: number ): Promise<DataView> => {
        return new Promise( ( resolve, reject ) => {
            let timeout = setTimeout( () => {
                this.device.removeEventListener( "inputreport", listener );
                reject( `Firmware backup timeout at byte offset ${ byteOffset }` );
            }, 5000 );

            const listener = ( event: HIDInputReportEvent ) => {
                const id = inputReportID( event.data );
                if ( id !== PacketId.FIRMWARE_UPDATE ) {
                    return;
                }

                const command: FirmwareUpdateCommand = event.data.getUint8( 1 );
                if ( command !== FirmwareUpdateCommand.READ_MEMORY) {
                    return;
                }

                clearTimeout( timeout );
                this.device.removeEventListener( "inputreport", listener );
                resolve( event.data );
            };

            this.device.addEventListener( "inputreport", listener );
            hidSend( this.device, this.serializeFirmwareMgmtRequest({ command: FirmwareUpdateCommand.READ_MEMORY, chunk, byteOffset }) );
        } );
    };

    private GetFlashInfo = async (): Promise<number> => {
        const response = await this.requestFlashInfo();

        const data = new Uint32Array(response.buffer)
        const dataOffset = 1; // Skip the packet and command ids
        const flashSize = data[dataOffset];
        const dualBankSupport = data[dataOffset + 1];
        const bankSize = data[dataOffset + 2];

        console.log("Flash Size is: ", flashSize);
        console.log("Dual Bank Supported: ", dualBankSupport === 1);
        console.log("Bank Size is: ", bankSize);

        return bankSize;
    }

    private readFlashMemory = async ( readLenBytes: number ): Promise<void> => {
        const wordSizeBytes = 4;
        const readLenWords = readLenBytes/wordSizeBytes;
        const MAX_CHUNK_LEN_WORDS = 13; // With the overhead removed (3 words), there is room for 52 bytes of data
        if ( readLenWords === undefined || readLenWords < 1 ) {
            console.error("A read size less than one is not permitted: ", readLenWords);
            return;
        }

        let offset = 0;
        while ( offset < readLenWords ) {
            const currentChunkSize = Math.min( MAX_CHUNK_LEN_WORDS, readLenWords - offset );
            const currentChunk = new Uint32Array(currentChunkSize);
            const response = await this.requestChunk( currentChunk, offset * wordSizeBytes, currentChunkSize );

            // PR_GATE: Debugging output until file write capability is ready
            let hex_string = "";
            let view = new Uint8Array(response.buffer);
            for (let i = 0; i < view.length; i++)
            {
                hex_string += view[i].toString(16) + " ";
            }
            console.log(hex_string);

            offset += currentChunkSize;
            this.callback?.( offset / readLenWords );
        }
    };
}

enum DfuRequest {
    Detach,
    Download,
    Upload,
    GetStatus,
    ClearStatus,
    GetState,
    Abort,
}

enum DfuState{
    appIDLE,
    appDETACH,
    dfuIDLE,
    dfuDNLOAD_SYNC,
    dfuDNBUSY,
    dfuDNLOAD_IDLE,
    dfuMANIFEST_SYNC,
    dfuMANIFEST,
    dfuMANIFEST_WAIT_RESET,
    dfuUPLOAD_IDLE,
    dfuERROR,
}

type DfuStatus = {
    bStatus: number,
    bwPollTimeout: number,
    bState: number,
    iString: number,
};

enum DfuBootloaderCommand {
    Get = 0x00,
    SetAddressPointer = 0x21,
    Erase = 0x41,
}
const DFU_CONFIGURATION_VALUE = 1;
const DFU_INTERFACE_INDEX = 0;

// TODO: remove DFU mode from this file
class FirmwareUpdateDFU {
    private device: USBDevice;
    private callback?: ( progress: number ) => void;

    constructor( device: USBDevice, callback?: ( progress: number ) => void ) {
        this.device = device;
        this.callback = callback;
    }

    run = async ( file: File ): Promise<boolean> => {
        await this.initializeDevice();

        try {
            await this.abort();
            await this.eraseMemory();
            await this.setAddressPointer( 0x08000000 );
            await this.flashBinary( file );
            await this.nBOOTset( true );
            await this.getStatus();
            return true;
        } catch ( e ) {
            console.warn( e );
        }

        return false;
    };

    private initializeDevice = async () => {
        await this.device.open();
        await this.device.selectConfiguration( DFU_CONFIGURATION_VALUE );
        await this.device.claimInterface( DFU_INTERFACE_INDEX );
    };

    private flashBinary = async ( file: File ): Promise<void> => {
        const bytes = await fileToBytes( file );
        const blockSize = 1024;

        let blockNum = 2;
        for ( let offset = 0; offset < bytes.length; offset += blockSize ) {
            const chunk = bytes.slice( offset, offset + blockSize );
            await this.transferOut( DfuRequest.Download, blockNum++, chunk );
            this.callback?.( offset / bytes.length );
            const status = await this.waitForNotState( DfuState.dfuDNBUSY );
            if ( status.bStatus !== 0x00 || status.bState !== DfuState.dfuDNLOAD_IDLE ) {
                throw new Error( `Failed to flash binary at offset ${ offset } (block ${ offset / blockSize }).\nStatus: 0x${ status.bStatus.toString( 16 ) }\nState: ${ status.bState }` );
            }
        }

        await this.transferOut( DfuRequest.Download, blockNum, new Uint8Array( 0 ) );
        await this.abort();
    };

    private waitForNotState = async ( state: number ) => {
        let status = await this.getStatus();
        while ( status.bState === state ) {
            const curTimeout = status.bwPollTimeout ?? 100;
            await new Promise( resolve => setTimeout( resolve, curTimeout ) );
            status = await this.getStatus();
        }
        return status;
    };

    private transferOut = async ( request: number, value: number, data?: BufferSource ): Promise<USBOutTransferResult> => {
        return await this.device.controlTransferOut({
            requestType: "class",
            recipient: "interface",
            request,
            value,
            index: DFU_INTERFACE_INDEX,
        }, data );
    };

    private transferIn = async ( request: number, value: number, byteLength: number ): Promise<USBInTransferResult> => {
        return await this.device.controlTransferIn({
            requestType: "class",
            recipient: "interface",
            request,
            value,
            index: DFU_INTERFACE_INDEX,
        }, byteLength );
    };

    private getStatus = async (): Promise<DfuStatus> => {
        const GET_STATUS_BYTE_LENGTH = 6;
        const result = await this.transferIn( DfuRequest.GetStatus, 0, GET_STATUS_BYTE_LENGTH );

        if ( result.status !== "ok" || result.data === undefined ) {
            console.warn( result );
            throw new Error( `Failed to get status: ${ result.status }` );
        }

        const dv = result.data;

        return {
            bStatus: dv.getUint8( 0 ),
            bwPollTimeout: dv.getUint32( 1, true ) & 0x00FFFFFF,
            bState: dv.getUint8( 4 ),
            iString: dv.getUint8( 5 ),
        };
    };

    private waitForState = async ( state: number, timeout: number = 1000 ): Promise<void> => {
        let status = await this.getStatus();
        let totalTimeout = 0;
        while ( status.bState !== state ) {
            if ( totalTimeout >= timeout ) {
                throw new Error( `Timeout waiting for state ${ state }` );
            }

            const curTimeout = status.bwPollTimeout ?? 100;
            if ( curTimeout === 0 && status.bState !== state ) {
                console.warn( status );
                throw new Error( `Expected state ${ state }, but got ${ status.bState }` );
            }

            totalTimeout += curTimeout;
            await new Promise( resolve => setTimeout( resolve, curTimeout ) );

            status = await this.getStatus();
        }
    };

    private setAddressPointer = async ( address: number ): Promise<void> => {
        const response = await this.transferOut( DfuRequest.Download, 0, new Uint8Array([
            DfuBootloaderCommand.SetAddressPointer,
            address & 0xFF,
            ( address >> 8 ) & 0xFF,
            ( address >> 16 ) & 0xFF,
            ( address >> 24 ) & 0xFF,
        ]));

        const status = await this.waitForNotState( DfuState.dfuDNBUSY );
        if ( response.status !== "ok" || status.bStatus !== 0x00 || status.bState !== DfuState.dfuDNLOAD_IDLE ) {
            throw new Error( `Failed to set address pointer: 0x${ address.toString( 16 ).padStart( 8, "0" ) }.\nResponse status: ${ response.status }\nStatus: 0x${ status.bStatus.toString( 16 ) }\nState: ${ status.bState }` );
        }

        console.info( `Address pointer -> 0x${ address.toString( 16 ).padStart( 8, "0" ) }` );
    };

    private eraseMemory = async (): Promise<void> => {
        const response = await this.transferOut( DfuRequest.Download, 0, new Uint8Array([
            DfuBootloaderCommand.Erase,
        ]));

        const status = await this.waitForNotState( DfuState.dfuDNBUSY );
        if ( response.status !== "ok" || status.bStatus !== 0x00 || status.bState !== DfuState.dfuDNLOAD_IDLE ) {
            throw new Error( `Failed to erase memory.\nResponse status: ${ response.status }\nStatus: 0x${ status.bStatus.toString( 16 ) }\nState: ${ status.bState }` );
        }

        console.info( "Erased flash." );
    };

    private readMemory = async ( address: number, byteLength: number ): Promise<DataView> => {
        await this.setAddressPointer( address );
        await this.abort();
        const response = await this.transferIn( DfuRequest.Upload, 2, byteLength );
        await this.abort();

        if ( response.status !== "ok" || response.data === undefined ) {
            throw new Error( `Failed to read ${ byteLength } bytes from 0x${ address.toString( 16 ).padStart( 8, "0" ) }: ${ response.status }` );
        }

        return response.data;
    };

    private writeMemory = async ( address: number, buffer: ArrayBuffer ): Promise<number> => {
        await this.setAddressPointer( address );
        const response = await this.transferOut( DfuRequest.Download, 2, buffer );
        if ( response.status !== "ok" ) {
            throw new Error( `Failed to write ${ buffer.byteLength } bytes to 0x${ address.toString( 16 ).padStart( 8, "0" ) }.\nResponse status: ${ response.status }` );
        }

        console.info( `Wrote ${ buffer.byteLength } bytes to ${ address.toString( 16 ).padStart( 8, "0" ) }.` );

        return response.bytesWritten;
    };

    private nBOOTset = async ( value: boolean ) => {
        await this.setOptionBit( 27, value );
    };

    private setOptionBit = async ( bit: number, value: boolean ) => {
        const OPTION_BYTES_ADDRESS = 0x1FFF7800;
        const OPTION_BYTES_BYTE_LENGTH = 8;
        const dv = await this.readMemory( OPTION_BYTES_ADDRESS, OPTION_BYTES_BYTE_LENGTH );
        const optionBytes = new Uint32Array( 2 );
        optionBytes[ 0 ] = dv.getUint32( 0, true );
        optionBytes[ 1 ] = dv.getUint32( 4, true );

        if ( optionBytes[ 0 ] !== ( ~optionBytes[ 1 ] ) >>> 0 ) {
            throw new Error( "Option bytes do not match complement" );
        }

        if ( value ) {
            optionBytes[ 0 ] |= 1 << bit;
        } else {
            optionBytes[ 0 ] &= ( ~( 1 << bit ) ) >>> 0;
        }

        optionBytes[ 1 ] = ( ~optionBytes[ 0 ] ) >>> 0;

        const bytesWritten = await this.writeMemory( OPTION_BYTES_ADDRESS, optionBytes.buffer );
        if ( bytesWritten !== optionBytes.buffer.byteLength ) {
            throw new Error( `Wrote ${ bytesWritten } bytes to 0x${ OPTION_BYTES_ADDRESS.toString( 16 ) }, expecting ${ optionBytes.buffer.byteLength }` );
        }
        console.info( `Option bit ${ bit } set to ${ value ? 1 : 0 }` );
    };

    private abort = async () => {
        await this.transferOut( DfuRequest.Abort, 0 );
        await this.waitForState( DfuState.dfuIDLE );
    }
}

// Kinda gross, but users can call this function if they get stuck in DFU mode
( window as any ).exitDFU = async () => {
    const device = await navigator.usb.requestDevice({
        filters: [{
            vendorId: DFU_VENDOR_ID,
            productId: DFU_PRODUCT_ID,
        }],
    });
    const dfu = new FirmwareUpdateDFU( device );
    console.info( dfu );
    await ( dfu as any ).initializeDevice();
    await ( dfu as any ).abort();
    await ( dfu as any ).nBOOTset( true );
}

( window as any ).updateFirmwareDFU = async () => {
    const device = await navigator.usb.requestDevice({
        filters: [{
            vendorId: DFU_VENDOR_ID,
            productId: DFU_PRODUCT_ID,
        }],
    });
    let lastProgress = 0;
    const dfu = new FirmwareUpdateDFU( device, progress => {
        const newProgress = Math.round( progress * 100 );
        if ( newProgress === lastProgress ) {
            return;
        }

        console.info( `Flashing... ${ newProgress }%` );
        lastProgress = newProgress;
    } );
    const input = document.createElement( "input" );
    input.type = "file";
    input.accept = ".bin";
    input.addEventListener( "change", async () => {
        const file = input.files?.[ 0 ];
        if ( file === undefined ) {
            return;
        }

        await dfu.run( file );
    } );
    input.click();
};
